export const APP_NAME = 'Poseidon Live Admin';
export const REACT_APP_BACKEND = 'https://psl2backend.poseidonsys.com';
export const PRIMARY_COLOR = '#113354';
export const SECONDARY_COLOR = '#fc6321';
export const HEADER_TITLE = 'Admin';
export const WHITELABEL = '';
export const APP_BAR_COLOR = '#113354';
export const HEADER_CONTRAST = '#FAFAFA';
export const DATA_FORWARDING_URLS = [{ name: 'LubeAnalyst Live', id: 'https://lubeanalyst.live/data' }];
export const LOGIN_URL = 'https://live.poseidonsys.com/login';
