import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions';
import ErrorMessage from '../common/ErrorMessage';
import { Typography, Container } from '@material-ui/core';
import FormSubmitButton from '../form/FormSubmitButton';

class MfaClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: '', // Initialize a state variable to store the verification code
    };
    this.submitOtp = this.submitOtp.bind(this);
  }

  handleVerificationCodeChange = (event) => {
    // Remove non-numeric characters and limit the code to 6 characters
    const numericValue = event.target.value.replace(/\D/g, '').slice(0, 6);
    this.setState({ verificationCode: numericValue });
  };

  resendCode = () => {
    this.props.sendMfaCode();
  };

  submitOtp() {
    const code = this.state.verificationCode;
    if (code.length === 6) {
      // Call your action to verify the OTP with the code
      this.props.verifyOtp(code, this.props.history);
    } else {
      ErrorMessage({
        msg: 'Verification code must be 6 characters long',
        timeOut: 60000,
      });
    }
  }

  render() {
    const {
      auth, isFetchingUser, isFetchingMfa, mfaVerified, isSendingMfa,
    } = this.props;
    if (((!auth && isFetchingUser === true) || isFetchingUser === null)
     || ((!mfaVerified && isFetchingMfa === true) || isFetchingMfa === null)) {
      return <p>Loading...</p>;
    }
    const indexOfAt = auth.email.indexOf('@');
    let charToShow = 3;
    let lengthBeforeAt = indexOfAt + 1;
    if (indexOfAt < 3) {
      lengthBeforeAt = 0;
      charToShow = indexOfAt;
    } else {
      lengthBeforeAt = indexOfAt - 2;
    }
    const lengthAfterAt = auth.email.length - indexOfAt;
    const emailRedacted = `${auth.email.substring(0, charToShow)
        + Array(lengthBeforeAt).join('*')}@${Array(lengthAfterAt).join('*')}`;
    return (
      <Container>
        <br />
        <Typography variant="h4">Multi-Factor Authentication Required</Typography>
        <br />

        <div className="ps-mfa-container">
          <p>
            Verification code will be sent to&nbsp;
            {emailRedacted}
            <span>
              <FormSubmitButton buttonClassName="right" onClick={this.resendCode} buttonText={isSendingMfa === false ? 'Resend' : 'Send Now'} />
            </span>
          </p>
          <br />
          <br />
          <p><b>Enter your 6-digit verification code:</b></p>
          <form onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission behavior
            this.submitOtp(); // Call the submitOtp function
          }}
          >
            <input
              className="ps-mfa-input"
              type="text"
              placeholder="Verification Code"
              value={this.state.verificationCode}
              onChange={this.handleVerificationCodeChange}
            />
            <FormSubmitButton buttonClassName="right" buttonText="Submit" />
          </form>
        </div>
      </Container>
    );
  }
}

function mapStateToProps({
  auth, isFetchingUser, isFetchingMfa, mfaVerified, isSendingMfa,
}) {
  return {
    auth, isFetchingUser, isFetchingMfa, mfaVerified, isSendingMfa,
  };
}
const Mfa = connect(mapStateToProps, actions)(MfaClass);
export default withRouter(Mfa);
