import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Field, FieldArray, change } from 'redux-form';
import _ from 'lodash';
import FormField from '../form/FormField';
import { DeviceTypeQuery } from '../../graphql/queries/DeviceTypeQueries';
import renderCheckbox from '../form/renderCheckbox';

class DeviceTypeConfig extends Component {
  constructor(props) {
    super(props);
    console.log('deviceTypeConfig!!', props);
    this.renderConfigFields = this.renderConfigFields.bind(this);
    this.setConfigKey = this.setConfigKey.bind(this);
  }

  setConfigKey(key, e, n, p) {
    console.log('setConfigKey', this.props.formName, key, e);
    this.props.setConfigKeyVal(this.props.formName, e, key, null);
  }

  renderConfigFields({ deviceType, fields, meta: { error, submitFailed } }) {
    const allFields = _.map(deviceType.fields, (obj, fieldIndex) => {
      console.log('renderConfigFields', obj);
      switch (obj.rule) {
        case 'String':
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`deviceTypeConfig[${fieldIndex}].v`}
                name={`deviceTypeConfig[${fieldIndex}].v`}
                type="text"
                component={FormField}
                label={obj.name}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
        case 'Number':
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`deviceTypeConfig[${fieldIndex}].v`}
                name={`deviceTypeConfig[${fieldIndex}].v`}
                type="text"
                component={FormField}
                label={obj.name}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
        case 'Boolean':
          return (
            <div key={`${fieldIndex}.tooltip`} data-tip={obj.description}>
              <Field
                key={`deviceTypeConfig[${fieldIndex}].v`}
                name={`deviceTypeConfig[${fieldIndex}].v`}
                label={obj.name}
                id={`deviceTypeConfig[${fieldIndex}].v`}
                component={renderCheckbox}
                onChange={(e, n, p) => this.setConfigKey(obj.key, e, n, p)}
              />
            </div>
          );
        default:
          return <div />;
      }
    });
    return allFields;
  }

  render() {
    if (!this.props.data) {
      return <div />;
    }
    const {
      data: { loading, error, deviceType },
    } = this.props;

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error Loading Device Configuration!</p>;
    }
    return (
      <div>
        <FieldArray
          name="deviceTypeConfig"
          component={this.renderConfigFields}
          props={{ deviceType }}
        />
      </div>
    );
  }
}
DeviceTypeConfig = connect(
  null,
  {
    setConfigKeyVal: (formName, event, newValue, previousValue) => {
      console.log('event', event);
      console.log('newValue', newValue);
      const name = `${event.currentTarget.name.slice(0, -1)}k`;
      return change(formName, name, newValue);
    },
  },
)(DeviceTypeConfig);

DeviceTypeConfig.defaultProps = {
  formName: 'Device',
};

export default graphql(DeviceTypeQuery, {
  skip: (ownProps) => !ownProps.deviceTypeId,
  options: (ownProps) => ({
    variables: { id: ownProps.deviceTypeId },
  }),
})(DeviceTypeConfig);
