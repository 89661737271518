import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import { connect } from 'react-redux';
import FormField from '../form/FormField';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import CompanyDropdown from '../company/CompanyDropdown';
import UserRoleDropdown from '../form/UserRoleDropdown';
import SsoDropdown from '../form/SsoDropdown';
import renderCheckbox from '../form/renderCheckbox';
import ExpansionPanelBase from '../common/ExpansionPanelBase';
import renderDropdownList from '../form/renderDropdownList';
import { CONST } from '../common/constants';

const FIELDS = [
  {
    label: 'First Name',
    name: 'firstName',
    type: 'text',
    error: 'Cannot be blank',
  },
  {
    label: 'Last Name',
    name: 'lastName',
    type: 'text',
    error: 'Cannot be blank',
  },
  {
    label: 'Email',
    name: 'email',
    type: 'text',
    error: 'Cannot be blank',
  },
];
class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole:
        props.initialValues && props.initialValues.role
          ? props.initialValues.role
          : null,
    };
    this.onUserRoleChange = this.onUserRoleChange.bind(this);
  }

  onUserRoleChange(event, newValue) {
    this.setState({ userRole: parseInt(newValue.id, 10) });
  }

  renderFields() {
    return _.map(FIELDS, ({ label, name, type }) => (
      <Field
        key={name}
        component={FormField}
        type={type}
        label={label}
        name={name}
      />
    ));
  }

  getAdditionalUserSettings() {
    const userSettingsOptions = [];
    userSettingsOptions[0] = {
      key: 'textOptions',
      header: 'Notification Options',
      data: (
        <div>
          <Field
            name="receiveEmail"
            component={renderCheckbox}
            label="Receive Email Notifications"
          />
          <Field
            name="receiveSMS"
            component={renderCheckbox}
            label="Receive Text Notifications"
          />
          <Field
            name="missedCommunicationAlerts"
            component={renderCheckbox}
            label="Send Missed Communication Alerts"
          />
        </div>
      ),
    };
    userSettingsOptions[1] = {
      key: 'configurationOptions',
      header: 'Configuration Options',
      data: (
        <div>
          <Field
            name="kinematicViscosityAssumedValue"
            component={FormField}
            type="text"
            label="Assumed fixed density (g/cm)3"
          />
          <Field
            name="kinematicViscosity"
            component={renderCheckbox}
            label="Viscosity: use estimated Kinematic Viscosity (cSt)"
          />
          <Field
            name="displayTempsF"
            component={renderCheckbox}
            label="Display Temperatures in Degrees F"
          />
        </div>
      ),
    };
    if (this.props.auth && this.props.auth.isSuperAdmin && this.state.userRole === CONST.SUPER_ADMIN_ROLE) {
      userSettingsOptions[2] = {
        key: 'superAdminOptions',
        header: 'Super Admin Options',
        data: (
          <div>
            <label>Account View</label>
            <Field
              name="accountOnlyView"
              component={renderDropdownList}
              data={CONST.VIEW_TYPES}
              textField="name"
              valueField="id"
              label="Account View"
            />
            <label>Notifications</label>
            <Field
              name="accountOnlyNotifications"
              component={renderDropdownList}
              data={CONST.VIEW_TYPES}
              textField="name"
              valueField="id"
              label="Notifications"
            />
            <Field
              name="obfuscate"
              component={renderCheckbox}
              label="Obfuscate Names"
            />
            <Field
              name="mismatchedDeviceAlerts"
              component={renderCheckbox}
              label="Send Mismatched Device Asset and AP2200 Asset Alerts"
            />
          </div>
        ),
      };
    }
    return userSettingsOptions;
  }

  render() {
    const {
      handleSubmit, pristine, reset, submitting, isCreate,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <CompanyDropdown fieldName="company" label="Company" />
        <br />
        {this.renderFields()}

        <UserRoleDropdown showSuperAdmin onDropdownChange={this.onUserRoleChange} />
        {
          this.props.auth && this.props.auth.isSuperAdmin ? (
            <SsoDropdown />
          ) : (<div />)
        }

        <br />
        {isCreate && isCreate === true && (
          <div>
            <Field
              name="sendWelcomeEmail"
              label="Send Welcome Email"
              component={renderCheckbox}
            />
            <br />
          </div>
        )}
        <ExpansionPanelBase
          panelData={this.getAdditionalUserSettings()}
        />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

UserForm.defaultProps = {
  initialValues: null,
};

const validate = (values) => {
  const errors = {};
  _.map(FIELDS, ({ name, error }) => {
    if (!values[name]) {
      errors[name] = error;
    }
  });
  if (!values.company) {
    errors.company = 'Cannot be blank';
  }
  return errors;
};
function mapStateToProps({ auth }) {
  return { auth };
}
UserForm = connect(
  mapStateToProps,
)(UserForm);
UserForm = reduxForm({ validate, form: 'User' })(UserForm);

export default UserForm;
