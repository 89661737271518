import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { formatRole } from '../common/functions';
import renderDropdownList from './renderDropdownList';
import axios from 'axios';
import { REACT_APP_BACKEND } from '../../config';

class SsoDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { ssoOptions: [] };
    this.handleSsoProviderChange = this.handleSsoProviderChange.bind(this);
  }

  handleSsoProviderChange(e, nv, pv) {
    this.props.onDropdownChange(e, nv, pv);
  }

  componentDidMount() {
    axios.get(`${REACT_APP_BACKEND}/sso/options`)
      .then((res) => {
        const resData = res.data;
        if (resData && Object.keys(resData).length > 0 && this.props.auth && this.props.auth.isSuperAdmin) {
          const ssoOptions = Object.keys(resData).map((key) => ({ id: key, name: String(key).charAt(0).toUpperCase() + String(key).slice(1) }));
          this.setState({ ssoOptions });
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  render() {
    return (
      <div>
        <label>SSO Provider</label>
        <Field
          key="ssoProvider"
          component={renderDropdownList}
          onChange={this.handleSsoProviderChange}
          data={this.state.ssoOptions}
          valueField="id"
          textField="name"
          label="SSO Provider"
          name="ssoProvider"
          canBeNull
        />
      </div>
    );
  }
}
SsoDropdown.defaultProps = {
  onDropdownChange: () => {},
};
function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(SsoDropdown);
