import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import update from 'immutability-helper';
import GridBase from '../common/GridBase';
import ContextMenuWithTrigger from '../common/ContextMenuWithTrigger';
import Modal from '../common/Modal';
import DeviceUpdate from '../device/DeviceUpdate';
import { Ap2200GridQuery } from '../../graphql/queries/DeviceQueries';
import { DeleteDeviceMutation } from '../../graphql/mutations/DeviceMutations';
import DeleteModel from '../common/DeleteModel';
import handleContextMenuClick from '../common/handleContextMenuClick';
import ap2200FormFields from './ap2200FormFields';
import DisplayRawData from '../common/DisplayRawData';
import displayTimestamp from '../common/displayTimestamp';
import timestampSort from '../common/timestampSort';
import cacheDeleteFromArray from '../../graphql/functions/cacheDeleteFromArray';

class Ap2200Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalTitle: '',
      modalType: '',
      device: {
        id: null, locationId: null, companyId: null, name: '',
      },
      delete: { id: null, name: '' },
      updateFn: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 50,
      filterable: true,
      show: true,
    },
    {
      Header: 'Company',
      id: 'companyName',
      accessor: (d) => (d.asset && d.asset.location && d.asset.location.company
        ? d.asset.location.company.name
        : null),
      maxWidth: 120,

      filterable: true,
    },
    {
      Header: 'Company Id',
      id: 'companyId',
      accessor: (d) => (d.asset && d.asset.location && d.asset.location.company
        ? d.asset.location.company.id
        : null),
      show: false,
    },
    {
      Header: 'Location',
      id: 'locationName',
      accessor: (d) => (d.asset && d.asset.location ? d.asset.location.name : null),
      maxWidth: 120,
      filterable: true,
    },
    {
      Header: 'Location Id',
      id: 'locationId',
      accessor: (d) => (d.asset && d.asset.location ? d.asset.location.id : null),
      show: false,
    },
    {
      Header: 'Asset',
      id: 'assetName',
      accessor: (d) => (d.asset ? d.asset.fullName + (d.installLocation ? ` ${d.installLocation}` : '') : null),
      minWidth: 110,
      filterable: true,
    },
    {
      Header: 'Parent Id',
      id: 'parentId',
      accessor: (d) => (d.asset && d.asset.parent ? d.asset.parent.id : null),
      show: false,
    },
    {
      Header: 'Parent Name',
      id: 'parentName',
      accessor: (d) => (d.asset && d.asset.parent ? d.asset.parent.name : null),
      show: false,
    },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      filterable: true,
    },
    {
      Header: 'Serial',
      accessor: 'serial',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Current Firmware',
      accessor: 'firmware',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Desired Firmware',
      id: 'desiredFirmware',
      accessor: (d) => (d.desiredFirmware && d.desiredFirmware.version ? d.desiredFirmware.version : null),
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Force Firmware Update',
      id: 'forceFirmwareUpdate',
      accessor: (d) => (d.forceFirmwareUpdate === null || d.forceFirmwareUpdate === false ? 'No' : 'Yes'),
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Request Log File',
      id: 'sendAp2200LogFile',
      accessor: (d) => (d.sendAp2200LogFile === null || d.sendAp2200LogFile === false ? 'No' : 'Yes'),
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Owner',
      id: 'owner',
      accessor: (d) => (d.owner ? d.owner.name : null),
      maxWidth: 70,
      filterable: true,
    },
    {
      Header: 'Latest Connection',
      id: 'latestConnection',
      accessor: (d) => displayTimestamp(d, 'latestConnection'),
      sortMethod: timestampSort,
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Install Date',
      id: 'installDate',
      accessor: (d) => displayTimestamp(d, 'installDate'),
      sortMethod: timestampSort,
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'MAC Address',
      accessor: 'macAddress',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'IP Address',
      accessor: 'ipAddress',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Subnet Mask',
      accessor: 'subnetMask',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: 'Gateway',
      accessor: 'gateway',
      maxWidth: 50,
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        duplicateOption: true,
        handleClick: this.handleClick,
        row,
        additionalOptions: [
          {
            key: 'latestData',
            label: 'view latest data',
            action: 'LatestData',
          },
        ],
      }),
    },
  ];

  // handle context menu clicks
  handleClick(e, data, target) {
    const modelName = 'device';
    const stateUpdate = handleContextMenuClick({
      e, data, target, modelName,
    });
    let updateFn;
    if (stateUpdate.delete && stateUpdate.delete.id) {
      updateFn = (cache) => {
        cacheDeleteFromArray({
          cache,
          deletedId: stateUpdate.delete.id,
          cacheOptions:
        [{ cachedListName: 'editableDevices' }, { cachedListName: 'editableAp2200s' }],
        });
      };
    }
    this.setState({ ...stateUpdate, updateFn });
  }

  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    const {
      data: { loading, error, ap2200s },
    } = this.props;
    const sorting = [
      { id: 'companyName', asc: true },
      { id: 'locationName', asc: true },
      { id: 'assetName', asc: true },
      { id: 'name', asc: true },
    ];
    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      console.log(error);
      return <p>Error loading AP2200s!</p>;
    }
    const multiSelect = {
      fields: ap2200FormFields,
      modelName: 'device',
      onSubmit: this.props.data.refetch,
    };
    return (
      <div>
        <GridBase
          dataVar={ap2200s}
          columns={this.columns}
          sorting={sorting}
          multiSelect={multiSelect}
        />

        <DeleteModel
          deleteMutation={DeleteDeviceMutation}
          update={this.state.updateFn}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />

        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={this.state.modalTitle}
        >
          {this.state.modalType && this.state.modalType === 'LatestData' ? (
            <DisplayRawData
              header={this.state.modalTitle}
              device={this.state.device}
            />
          ) : (
            <DeviceUpdate
              device={this.state.device}
              header={this.state.modalTitle}
              duplicate={
                  !!(this.state.modalType && this.state.modalType === 'Duplicate')
                }
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default graphql(Ap2200GridQuery, {
  name: 'Ap2200GridQuery',
  props: ({
    ownProps,
    Ap2200GridQuery: {
      loading, error, editableAp2200s, refetch,
    },
  }) => {
    if (editableAp2200s) {
      editableAp2200s = editableAp2200s.map((device) => {
        if (device.asset && device.asset.parent) {
          device = update(device, {
            asset: { location: { $set: device.asset.parent.location } },
          });
        }
        return device;
      });
    }
    return {
      data: {
        ap2200s: editableAp2200s,
        loading,
        error,
        refetch,
      },
    };
  },
})(Ap2200Grid);
