import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';

import UserForm from './UserForm';
import FormBase from '../form/FormBase';
import { CreateUserMutation } from '../../graphql/mutations/UserMutations';
import {
  UserGridQuery,
  UserSelectQuery,
} from '../../graphql/queries/UserQueries';
import handleUserMutation from '../../graphql/functions/handleUserMutation';

class UserNew extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      if (values.role) {
        values.role = values.role.id;
      }
      if (values.ssoProvider) {
        values.ssoProvider = values.ssoProvider.id;
      } else {
        delete values.ssoProvider;
      }
    }
    handleUserMutation({
      values,
      mutation: this.props.createUserMutation,
      refetchQueries: [{ query: UserGridQuery }, { query: UserSelectQuery }],
      onSuccess: this.props.onSuccess,
    });
  }

  render() {
    return (
      <FormBase header="Add user">
        <UserForm
          isCreate
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

export default graphql(CreateUserMutation, { name: 'createUserMutation' })(
  UserNew,
);
