import React from 'react';
import { Field } from 'redux-form';
import FormField from '../form/FormField';
import renderCheckbox from '../form/renderCheckbox';
import DeviceTypeDropdown from './deviceType/DeviceTypeDropdown';
import DeviceConfigDropdown from './deviceConfig/DeviceConfigDropdown';
import CompanyDropdown from '../company/CompanyDropdown';
import renderDropdownList from '../form/renderDropdownList';
import { DATA_FORWARDING_URLS } from '../../config';

export default [
  {
    id: 'name',
    name: 'Name',
    field: (
      <Field
        name="name"
        type="text"
        component={FormField}
        label="Device Name"
      />
    ),
  },
  {
    id: 'type',
    name: 'Device Type',
    field: <DeviceTypeDropdown fieldName="deviceType" label="Device Type" />,
  },
  {
    id: 'config',
    name: 'Device Configuration',
    field: (
      <DeviceConfigDropdown
        fieldName="deviceConfig"
        label="Device Configuration"
      />
    ),
  },
  {
    id: 'owner',
    name: 'Device Owner',
    field: <CompanyDropdown fieldName="owner" label="Device Owner" />,
  },
  {
    id: 'additionalOptions',
    name: 'Additional Options',
    field: (
      <Field
        name="additionalOptions"
        component="textarea"
        label="Additional Options JSON"
        placeholder='{"targetTemp":100}'
      />
    ),
  },
  {
    id: 'active',
    name: 'Active',
    field: (
      <Field
        name="active"
        label="Active"
        component={renderCheckbox}
      />
    ),
  },
  {
    id: 'secondsBetweenPts',
    name: 'Seconds Between Points',
    field: (
      <Field
        name="secondsBetweenPts"
        type="number"
        parse={(value) => (!value ? null : Number(value))}
        component={FormField}
        label="Seconds Between Points"
      />
    ),
  },
  {
    id: 'dataForwardUrl',
    name: 'Forward Data Site',
    field: (
      <Field
        name="dataForwardUrl"
        component={renderDropdownList}
        data={DATA_FORWARDING_URLS}
        valueField="id"
        textField="name"
        label="Forward Data to this Site"
        canBeNull
      />
    ),
  },
  {
    id: 'dataForwardOnly',
    name: 'Forward Data Only',
    field: (
      <Field
        name="dataForwardOnly"
        label="Forward Data Only"
        component={renderCheckbox}
      />
    ),
  },
  {
    id: 'isBackfilling',
    name: 'Is Backfilling',
    field: (
      <Field
        name="isBackfilling"
        label="Is Backfilling"
        component={renderCheckbox}
      />
    ),
  },
];
