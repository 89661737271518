import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import PSLoader from '../../PSLoader';
import Typography from '@material-ui/core/Typography';
import handleMultiDeleteMutation from './handleMultiDeleteMutation';

// Function to handle pluralization
const getPluralizedName = (modelName, count) => {
  const lowercaseModelName = modelName.toLowerCase();

  if (lowercaseModelName === 'company') {
    return count === 1 ? 'company' : 'companies';
  }

  return count === 1 ? lowercaseModelName : `${lowercaseModelName}s`;
};

// Define the MultiDelete component
class MultiDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    };
  }

  onSubmit = () => {
    this.setState({ showLoader: true });
    handleMultiDeleteMutation({
      modelName: this.props.modelName,
      ids: this.props.ids,
      onSuccess: this.onSuccess,
      onError: this.onError,
    });
  };

  onSuccess = (values) => {
    this.onFinally();
  };

  onError = (error) => {
    this.onFinally();
  };

  onFinally = () => {
    this.setState({ showLoader: false });
    this.props.onSubmit();
  };

  render() {
    const { modelName, ids } = this.props;
    const { showLoader } = this.state;
    const count = ids.length;
    const pluralizedName = getPluralizedName(modelName, count);

    return (
      <div>
        <PSLoader showLoader={showLoader}>
          <div>
            <Typography variant="h4">
              Delete
              {' '}
              {pluralizedName}
            </Typography>
            <br />
            <p>
              Are you sure you would like to delete
              {' '}
              {count > 1 ? 'these ' : 'this '}
              <strong>{count}</strong>
              {' '}
              {pluralizedName}
              ?
            </p>
            <div>
              <Button onClick={this.onSubmit}>Confirm</Button>
            </div>
          </div>
        </PSLoader>
      </div>
    );
  }
}

// Set default props for MultiDelete
MultiDelete.defaultProps = {
  modelName: '',
  ids: [],
};

// Export the MultiDelete component
export default MultiDelete;
