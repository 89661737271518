import { gql } from '@apollo/client';
import { userAllFragment } from '../fragments';

const CreateUserMutation = gql`
  mutation createUser($user: UserInput!) {
    createUser(user: $user) {
      ...userAll
    }
  }
  ${userAllFragment}
`;

const UpdateUserMutation = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      ...userAll
    }
  }
  ${userAllFragment}
`;

const DeleteUserMutation = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      email
    }
  }
`;

const SendPasswordEmailToUser = gql`
  mutation sendPasswordResetEmail($id: ID!) {
    sendPasswordResetEmail(id: $id) {
      responseValue
    }
  }
`;

const SendWelcomeEmailToUser = gql`
  mutation createAndSendWelcomeEmail($id: ID!) {
    createAndSendWelcomeEmail(id: $id) {
      responseValue
    }
  }
`;

export {
  CreateUserMutation, UpdateUserMutation, DeleteUserMutation, SendPasswordEmailToUser, SendWelcomeEmailToUser,
};
